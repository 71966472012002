/* eslint camelcase: 0 */
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import DropZone from '../components/ui/DropZone';
import RadioButtonGroup from '../components/ui/RadioButtonGroup';
import RadioButton from '../components/ui/RadioButton';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  getAvailableDistributorsCountLS,
  getBillPresenceType,
  getUserType,
  setBillPresenceType,
  setControlledLoadUsage,
  setEndDate,
  setHasControlledLoad,
  setParsedBillIdLS,
  setParsedBillLS,
  setPeakOffPeakUsage,
  setSingleRateUsage,
  setStartDate,
  setTariffType,
} from '../utils/localStorage/localStorageFunctions';
import { BillPresenceLsType } from '../utils/localStorage/localStorageTypes';
import { UPLOAD_BILL } from '../apollo/queries';
import {
  UploadBill,
  UploadBill_uploadBill_parsedBill,
  UploadBillVariables,
} from '../apollo/__generated__/UploadBill';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const BillType = () => {
  const [uploadBill, { data: billUploadData, loading: billUploadLoading }] =
    useMutation<UploadBill, UploadBillVariables>(UPLOAD_BILL);

  const [userType] = useState(() => {
    return getUserType();
  });
  const [myFiles, setMyFiles] = useState([]);
  const [hasBillError, setHasBillError] = useState(false);
  const [isBillUploadSuccess, setIsBillUploadSuccess] = useState(false);
  const [parsedBill, setParsedBill] =
    useState<UploadBill_uploadBill_parsedBill | null>();
  const [parsedBillId, setParsedBillId] = useState<string | null>();

  const clearMyFiles = () => {
    setParsedBill(null);
    setParsedBillId(null);
    setMyFiles([]);
    setHasBillError(false);
    setIsBillUploadSuccess(false);
  };

  const initUpload = (files: File[]) => {
    if (!files || !files?.length) return;
    uploadBill({ variables: { file: files[0] } });
  };

  useEffect(() => {
    if (!billUploadData || billUploadLoading) return;

    if (!billUploadData?.uploadBill?.parsedBill) {
      setHasBillError(true);
      return;
    }

    setIsBillUploadSuccess(true);
    setParsedBill(billUploadData?.uploadBill?.parsedBill);
    setParsedBillId(billUploadData?.uploadBill?.bill?.id);
  }, [billUploadData, billUploadLoading]);

  const onDrop = useCallback((acceptedFiles) => {
    clearMyFiles();
    setMyFiles(acceptedFiles);
    initUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf',
    maxFiles: 1,
    maxSize: 12_000_000, // 12MB
  });

  const [initialFormValues] = useState(() => {
    const billTypeLS = getBillPresenceType();
    return { billAvailable: billTypeLS || '' };
  });

  const destructureAndSaveBillData = (
    parsedBillData: UploadBill_uploadBill_parsedBill
  ) => {
    const {
      startDate,
      endDate,
    } = parsedBillData;

    setStartDate(startDate);
    setEndDate(endDate);

    const peak = Number(parsedBillData.peak);
    const offPeak = Number(parsedBillData.offPeak);
    const shoulder = Number(parsedBillData.shoulder);
    const generalUsage = Number(parsedBillData.generalUsage);
    const clUsage = Number(parsedBillData.clUsage);

    if (peak || offPeak || shoulder) {
      setTariffType('peakOff');
      setPeakOffPeakUsage({
        peakUsage: peak ?? 0,
        offPeakUsage: offPeak ?? 0,
        shoulderUsage1: shoulder ?? 0,
      });
    }

    if (generalUsage) {
      setTariffType('single');
      setSingleRateUsage(generalUsage);
    }

    if (clUsage) {
      setControlledLoadUsage({ controlledLoadUsage1: clUsage });
      setHasControlledLoad('yes');
    }

    navigateAndSetActiveStep(StepUrl.URL_SOLAR_INSTALLED);
  };

  const handleBillTypeSelection = (values: typeof initialFormValues) => {
    const billType = values.billAvailable as BillPresenceLsType;
    setBillPresenceType(billType);

    if (userType === 'household') {
      if (billType === 'paperBill' || billType === 'noBill') {
        navigateAndSetActiveStep(StepUrl.URL_YOUR_HOUSEHOLD);
      }
    }

    if (userType === 'business') {
      if (billType === 'paperBill') {
        navigateAndSetActiveStep(StepUrl.URL_ABOUT_YOUR_BILL);
      }
    }

    if (billType === 'pdfBill' && parsedBill && parsedBillId) {
      setParsedBillLS(parsedBill);
      setParsedBillIdLS(parsedBillId);
      destructureAndSaveBillData(parsedBill);
    }
  };

  const handleBack = () => {
    const distributorCountLS = getAvailableDistributorsCountLS();
    if ((distributorCountLS ?? 0) > 1) {
      navigateAndSetActiveStep(StepUrl.URL_DISTRIBUTOR_CHOICE);
      return;
    }

    if (userType === 'business') {
      navigateAndSetActiveStep(StepUrl.URL_POSTCODE_BUSINESS);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_POSTCODE_HOUSEHOLD);
  };

  usePreloadPageData(
    StepUrl.URL_YOUR_HOUSEHOLD,
    StepUrl.URL_ABOUT_YOUR_BILL,
    StepUrl.URL_SOLAR_INSTALLED,
    StepUrl.URL_DISTRIBUTOR_CHOICE,
    StepUrl.URL_POSTCODE_BUSINESS,
    StepUrl.URL_POSTCODE_HOUSEHOLD
  );

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={1}
        currentStep={3}
        pageUrl={StepUrl.URL_BILL_TYPE}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleBillTypeSelection(values);
            }}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Do you have a recent bill handy?</h2>
                <RadioButtonGroup className="my-2">
                  <RadioButton value="pdfBill" name="billAvailable" id="rad1">
                    I have a pdf bill
                  </RadioButton>
                </RadioButtonGroup>
                {props.values.billAvailable === 'pdfBill' && (
                  <DropZone
                    className="mt-4 mb-2"
                    style={{ width: '100%' }}
                    acceptedFiles={myFiles}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                    isActive={props.values.billAvailable === 'pdfBill'}
                    clearFiles={clearMyFiles}
                    hasBillError={hasBillError}
                    isBillUploadSuccess={isBillUploadSuccess}
                  />
                )}
                <RadioButtonGroup className="mt-2">
                  <RadioButton value="paperBill" name="billAvailable" id="rad2">
                    I have a paper bill
                  </RadioButton>
                  {userType === 'household' && (
                    <RadioButton value="noBill" name="billAvailable" id="rad3">
                      I don&apos;t have a bill
                    </RadioButton>
                  )}
                </RadioButtonGroup>
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={
                    !(
                      props.values.billAvailable === 'paperBill' ||
                      props.values.billAvailable === 'noBill' ||
                      parsedBillId
                    )
                  }
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <BackButton text="Back" onClick={handleBack} />
      </StepperLayout>
    </SEOLayout>
  );
};

export default BillType;
