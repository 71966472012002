import React from 'react';
// @ts-ignore
import { ReactComponent as OkIcon } from '../../../assets/vectors/OkIcon.svg';
// @ts-ignore
import { ReactComponent as WarningIcon } from '../../../assets/vectors/WarningIcon.svg';

const style = require('./InfoMessage.module.scss');

type InfoMessageProps = {
  variant: 'success' | 'warning';
  className?: string;
  children: React.ReactNode;
  [otherProps: string]: any;
};

const getMessageIcon = (iconVariant: string) => {
  switch (iconVariant) {
    case 'success':
      return <OkIcon width={24} height={24} />;
    case 'warning':
    default:
      return <WarningIcon width={24} height={24} />;
  }
};

const getMessageExtraClass = (styleVariant: string) => {
  switch (styleVariant) {
    case 'success':
      return style.infoMessageSuccess;
    case 'warning':
    default:
      return style.infoMessageWarning;
  }
};

const InfoMessage = (props: InfoMessageProps) => {
  const { className, variant, children, ...rest } = props;

  return (
    <div
      {...rest}
      className={`${style.infoMessage} ${getMessageExtraClass(
        variant
      )} ${className}`}
    >
      <div className={style.messageIcon}>{getMessageIcon(variant)}</div>
      <p className={style.infoMessageText}>{children}</p>
    </div>
  );
};

InfoMessage.defaultProps = {
  className: '',
};

export default InfoMessage;
